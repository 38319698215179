import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useLoggerStore = defineStore('logger-store', () => {
    const logStatuses = ref({
        'raw': true,
        'props': false,
        'drop': true,
        'drop.1': false,
    });

    const logger = {
        log: (context, title, data) => {
            if (logStatuses.value[context]) {
                console.log(title, data);
            }
        },
        dir: (context, title, data) => {
            if (logStatuses.value[context]) {
                console.log(title);
                console.dir(data);
            }
        }
    }

    return {
        logger
    };
});

